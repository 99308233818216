import {request} from '@/common/request/admin.js'

export const site = {
	upload(data){
		return request.post('/backend/upload/index',data,{
			headers: {
				'Content-Type' : 'multipart/form-data'
			},
			timeout:60*20*1000
		})
	}
}

export const attachment = {
	all(params){
		return request.get('/backend/attachment/all',{params})
	},
}

export const auth = {
	login(data){
		return request.post('/backend/auth/login',data)
	},
	logout(){
		return request.post('/backend/auth/logout')
	},
	getLoginInfo(){
		return request.post('/backend/auth/getLoginInfo')
	}
}

export const dashboard = {
	pv(params){
		return request.get('/backend/dashboard/pv',{params})
	},
	user(params){
		return request.get('/backend/dashboard/user',{params})
	},
}

export const config = {
	get(params){
		return request.get('/backend/config/get',{params})
	},
	create(data){
		return request.post('/backend/config/create',data)
	}
}

export const admin = {
	get(params){
		return request.get('/backend/admin/get',{params})
	},
	all(params){
		return request.get('/backend/admin/all',{params})
	},
	create(data){
		return request.post('/backend/admin/create',data)
	},
	delete(data){
		return request.post('/backend/admin/delete',data)
	},
}

export const sensitive = {
	all(params){
		return request.get('/backend/sensitive/all',{params})
	},
	create(data){
		return request.post('/backend/sensitive/create',data)
	},
	delete(data){
		return request.post('/backend/sensitive/delete',data)
	},
}

export const article = {
	get(params){
		return request.get('/backend/article/get',{params})
	},
	all(params){
		return request.get('/backend/article/all',{params})
	},
	create(data){
		return request.post('/backend/article/create',data)
	},
	delete(data){
		return request.post('/backend/article/delete',data)
	},
	changeOrder(data){
		return request.post('/backend/article/changeOrder',data)
	},
}


export const user = {
	get(params){
		return request.get('/backend/user/get',{params})
	},
	all(params){
		return request.get('/backend/user/all',{params})
	}
}

export const cases = {
	get(params){
		return request.get('/backend/cases/get',{params})
	},
	all(params){
		return request.get('/backend/cases/all',{params})
	},
	create(data){
		return request.post('/backend/cases/create',data)
	},
	delete(data){
		return request.post('/backend/cases/delete',data)
	},
	changeOrder(data){
		return request.post('/backend/cases/changeOrder',data)
	},
}

export const partner = {
	get(params){
		return request.get('/backend/partner/get',{params})
	},
	all(params){
		return request.get('/backend/partner/all',{params})
	},
	create(data){
		return request.post('/backend/partner/create',data)
	},
	delete(data){
		return request.post('/backend/partner/delete',data)
	},
	changeOrder(data){
		return request.post('/backend/partner/changeOrder',data)
	},
}

export const message = {
	get(params){
		return request.get('/backend/message/get',{params})
	},
	all(params){
		return request.get('/backend/message/all',{params})
	},
	create(data){
		return request.post('/backend/message/create',data)
	},
	delete(data){
		return request.post('/backend/message/delete',data)
	},
}

export const wechat = {
	get(params){
		return request.get('/backend/wechat/get',{params})
	},
	all(params){
		return request.get('/backend/wechat/all',{params})
	},
	create(data){
		return request.post('/backend/wechat/create',data)
	},
	delete(data){
		return request.post('/backend/wechat/delete',data)
	},
	changeOrder(data){
		return request.post('/backend/wechat/changeOrder',data)
	},
}


export const account = {
	get(params){
		return request.get('/backend/account/get',{params})
	},
	all(params){
		return request.get('/backend/account/all',{params})
	},
	create(data){
		return request.post('/backend/account/create',data)
	},
	delete(data){
		return request.post('/backend/account/delete',data)
	},
}

export const email = {
	test(data){
		return request.post('/backend/email/test',data)
	}
}

export const email_template = {
	get(params){
		return request.get('/backend/email_template/get',{params})
	},
	all(params){
		return request.get('/backend/email_template/all',{params})
	},
	create(data){
		return request.post('/backend/email_template/create',data)
	},
	delete(data){
		return request.post('/backend/email_template/delete',data)
	},
	changeOrder(data){
		return request.post('/backend/email_template/changeOrder',data)
	},
}
